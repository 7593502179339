window.setModalTitle = function (title) {
  $('#modal-window .modal-header h4').html(title)
}

window.setModalContent = function (content) {
  const closeButton = '<button aria-hidden="true" class="btn btn-outline-primary" data-dismiss="modal">Fermer</button>'

  $('#modal-window .modal-body').html(content)
  $('#modal-window .modal-body .modal-footer').prepend(closeButton)
}
