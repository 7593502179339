import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://9a3170c5c00c48e1a25237326d80d047@o28764.ingest.sentry.io/5720920',

    // Configures the sample rate for error events, in the range of 0.0 to 1.0.
    // The default is 1.0 which means that 100% of error events are sent. If set
    // to 0.1 only 10% of error events will be sent. Events are picked randomly.
    sampleRate: 0.2,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    // Configure environment with hostname
    environment: window.location.hostname.includes('staging') ? 'staging' : 'production'
  })
}
