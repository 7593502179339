// Entry point for the build script in your package.json

// Load global config used once per JS loading
import '../initializers'
import 'bootstrap'

import { Turbo } from '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import Rails from '@rails/ujs'

// Load Stimulus controllers
import '../controllers'

import '@/lib'

// Disable Turbo Drive to keep as before
Turbo.session.drive = false

// Start UJS
window.Rails = Rails
if (!window._rails_loaded) Rails.start()

// Start ActiveStorage
ActiveStorage.start()
