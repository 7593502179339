/* global Notifications */

window.Notifications = {}
;(function () {
  this.hideTimeout = null
  this.nextShowTimeout = null

  this.init = function () {
    let started = false

    $('.notification:not([data-shown="true"])').each((index, element) => {
      const $element = $(element)
      $element.attr('data-shown', 'false')

      if (!started) {
        started = true
        Notifications.show($element)
      }
    })
  }

  this.destroy = function () {
    $('.notification.visible').removeClass('visible')
    Notifications.clearTimeouts()
  }

  this.clearTimeouts = function () {
    if (Notifications.hideTimeout) {
      clearTimeout(Notifications.hideTimeout)
    }
    if (Notifications.nextShowTimeout) {
      clearTimeout(Notifications.nextShowTimeout)
    }
  }

  this.show = function ($element) {
    $element.addClass('visible')
    $element.find('.notification-close').on('click', Notifications.close)

    if ($element.data('autoDismiss')) {
      Notifications.hideTimeout = setTimeout(Notifications.hide, 5000, $element)
    }
  }

  this.showNext = function ($prevElement, $nextElement) {
    $prevElement.removeClass('slideOut')
    Notifications.show($nextElement)
  }

  this.hide = function ($element) {
    $element.addClass('slideOut').removeClass('visible').attr('data-shown', 'true')
    $element.find('.notification-close').off('click')
    const $nextElement = $('.notification[data-shown="false"]').first()

    if ($nextElement) {
      Notifications.nextShowTimeout = setTimeout(Notifications.showNext, 600, $element, $nextElement)
    }
  }

  this.close = function (event) {
    event.preventDefault()
    Notifications.clearTimeouts()
    Notifications.hide($(this).parents('.notification'))
    return false
  }

  this.flash = function (style, message) {
    const el = document.createElement('div')
    el.classList.add('notification')
    el.classList.add(`notification-${style}`)
    el.role = 'alert'
    el.dataset.autoDismiss = true
    el.innerHTML = `
      <div class="notification-content">
        <div class="notification-text">${message}</div>
        <button type="button" class="notification-close">Fermer</button>
      </div>
    `

    document.querySelector('.notifications').append(el)
    this.show($(el))
  }
}.apply(window.Notifications))

document.addEventListener('turbo:load', () => {
  window.Notifications.init()
})
