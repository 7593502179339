// CAUTION: Do not edit this file. It has been generated automatically.
// NOTE: You can add more icons by editing config/fontawesome.yml and
// running `bin/fontawesome` after.
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF as fabFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faLinkedinIn as fabLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faAngleDown as falAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faArrowAltToBottom as falArrowAltToBottom } from '@fortawesome/pro-light-svg-icons/faArrowAltToBottom'
import { faArrowToBottom as falArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom'
import { faBell as falBell } from '@fortawesome/pro-light-svg-icons/faBell'
import { faCalendarAlt as falCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt'
import { faChevronDown as falChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faEdit as falEdit } from '@fortawesome/pro-light-svg-icons/faEdit'
import { faFileCertificate as falFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate'
import { faFileDownload as falFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload'
import { faFileImport as falFileImport } from '@fortawesome/pro-light-svg-icons/faFileImport'
import { faGlobeEurope as falGlobeEurope } from '@fortawesome/pro-light-svg-icons/faGlobeEurope'
import { faHeadSideBrain as falHeadSideBrain } from '@fortawesome/pro-light-svg-icons/faHeadSideBrain'
import { faInfoCircle as falInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faLanguage as falLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage'
import { faLightbulbDollar as falLightbulbDollar } from '@fortawesome/pro-light-svg-icons/faLightbulbDollar'
import { faLock as falLock } from '@fortawesome/pro-light-svg-icons/faLock'
import { faMedal as falMedal } from '@fortawesome/pro-light-svg-icons/faMedal'
import { faMinusCircle as falMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle'
import { faMoneyBillWave as falMoneyBillWave } from '@fortawesome/pro-light-svg-icons/faMoneyBillWave'
import { faPaperclip as falPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip'
import { faPhone as falPhone } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faQuestionCircle as falQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import { faShare as falShare } from '@fortawesome/pro-light-svg-icons/faShare'
import { faSquare as falSquare } from '@fortawesome/pro-light-svg-icons/faSquare'
import { faTag as falTag } from '@fortawesome/pro-light-svg-icons/faTag'
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faTrash as falTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faUndo as falUndo } from '@fortawesome/pro-light-svg-icons/faUndo'
import { faUserHeadset as falUserHeadset } from '@fortawesome/pro-light-svg-icons/faUserHeadset'
import { faUsers as falUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faWindowClose as falWindowClose } from '@fortawesome/pro-light-svg-icons/faWindowClose'
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { faArrowLeft as farArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import { faArrowRight as farArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faBalanceScaleLeft as farBalanceScaleLeft } from '@fortawesome/pro-regular-svg-icons/faBalanceScaleLeft'
import { faBan as farBan } from '@fortawesome/pro-regular-svg-icons/faBan'
import { faBars as farBars } from '@fortawesome/pro-regular-svg-icons/faBars'
import { faBell as farBell } from '@fortawesome/pro-regular-svg-icons/faBell'
import { faBriefcaseMedical as farBriefcaseMedical } from '@fortawesome/pro-regular-svg-icons/faBriefcaseMedical'
import { faBuilding as farBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faCheckCircle as farCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import { faCheckDouble as farCheckDouble } from '@fortawesome/pro-regular-svg-icons/faCheckDouble'
import { faCheckSquare as farCheckSquare } from '@fortawesome/pro-regular-svg-icons/faCheckSquare'
import { faChevronDoubleDown as farChevronDoubleDown } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleDown'
import { faChevronDoubleUp as farChevronDoubleUp } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleUp'
import { faChevronDown as farChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronUp as farChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faCirclePlus as farCirclePlus } from '@fortawesome/pro-regular-svg-icons/faCirclePlus'
import { faClipboard as farClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard'
import { faClock as farClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faCocktail as farCocktail } from '@fortawesome/pro-regular-svg-icons/faCocktail'
import { faCog as farCog } from '@fortawesome/pro-regular-svg-icons/faCog'
import { faCogs as farCogs } from '@fortawesome/pro-regular-svg-icons/faCogs'
import { faCoins as farCoins } from '@fortawesome/pro-regular-svg-icons/faCoins'
import { faComment as farComment } from '@fortawesome/pro-regular-svg-icons/faComment'
import { faEdit as farEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faEnvelopeOpen as farEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpen'
import { faEnvelopeOpenText as farEnvelopeOpenText } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText'
import { faEuroSign as farEuroSign } from '@fortawesome/pro-regular-svg-icons/faEuroSign'
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle'
import { faExclamationTriangle as farExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink'
import { faEye as farEye } from '@fortawesome/pro-regular-svg-icons/faEye'
import { faFile as farFile } from '@fortawesome/pro-regular-svg-icons/faFile'
import { faFileDownload as farFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload'
import { faFileInvoiceDollar as farFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar'
import { faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons/faFlag'
import { faFootballBall as farFootballBall } from '@fortawesome/pro-regular-svg-icons/faFootballBall'
import { faHandHoldingUsd as farHandHoldingUsd } from '@fortawesome/pro-regular-svg-icons/faHandHoldingUsd'
import { faHeart as farHeart } from '@fortawesome/pro-regular-svg-icons/faHeart'
import { faHeartbeat as farHeartbeat } from '@fortawesome/pro-regular-svg-icons/faHeartbeat'
import { faHourglass as farHourglass } from '@fortawesome/pro-regular-svg-icons/faHourglass'
import { faInfoCircle as farInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faLockAlt as farLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt'
import { faLongArrowRight as farLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight'
import { faPhone as farPhone } from '@fortawesome/pro-regular-svg-icons/faPhone'
import { faPhoneAlt as farPhoneAlt } from '@fortawesome/pro-regular-svg-icons/faPhoneAlt'
import { faPlus as farPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faShieldHalved as farShieldHalved } from '@fortawesome/pro-regular-svg-icons/faShieldHalved'
import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons/faStar'
import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faTimesCircle as farTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle'
import { faToggleOff as farToggleOff } from '@fortawesome/pro-regular-svg-icons/faToggleOff'
import { faToggleOn as farToggleOn } from '@fortawesome/pro-regular-svg-icons/faToggleOn'
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faCheckSquare as fasCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare'
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faClipboard as fasClipboard } from '@fortawesome/pro-solid-svg-icons/faClipboard'
import { faComments as fasComments } from '@fortawesome/pro-solid-svg-icons/faComments'
import { faCommentsAlt as fasCommentsAlt } from '@fortawesome/pro-solid-svg-icons/faCommentsAlt'
import { faCopy as fasCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faDownload as fasDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
import { faEngineWarning as fasEngineWarning } from '@fortawesome/pro-solid-svg-icons/faEngineWarning'
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faEuroSign as fasEuroSign } from '@fortawesome/pro-solid-svg-icons/faEuroSign'
import { faExternalLink as fasExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import { faExternalLinkSquare as fasExternalLinkSquare } from '@fortawesome/pro-solid-svg-icons/faExternalLinkSquare'
import { faFileCertificate as fasFileCertificate } from '@fortawesome/pro-solid-svg-icons/faFileCertificate'
import { faInfo as fasInfo } from '@fortawesome/pro-solid-svg-icons/faInfo'
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faLink as fasLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faLock as fasLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { faLockAlt as fasLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt'
import { faLongArrowRight as fasLongArrowRight } from '@fortawesome/pro-solid-svg-icons/faLongArrowRight'
import { faMegaphone as fasMegaphone } from '@fortawesome/pro-solid-svg-icons/faMegaphone'
import { faMinusCircle as fasMinusCircle } from '@fortawesome/pro-solid-svg-icons/faMinusCircle'
import { faMobile as fasMobile } from '@fortawesome/pro-solid-svg-icons/faMobile'
import { faMoneyBillWave as fasMoneyBillWave } from '@fortawesome/pro-solid-svg-icons/faMoneyBillWave'
import { faPen as fasPen } from '@fortawesome/pro-solid-svg-icons/faPen'
import { faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faPhoneAlt as fasPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faShieldHalved as fasShieldHalved } from '@fortawesome/pro-solid-svg-icons/faShieldHalved'
import { faShoppingBag as fasShoppingBag } from '@fortawesome/pro-solid-svg-icons/faShoppingBag'
import { faSimCard as fasSimCard } from '@fortawesome/pro-solid-svg-icons/faSimCard'
import { faSpinner as fasSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner'
import { faSquare as fasSquare } from '@fortawesome/pro-solid-svg-icons/faSquare'
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { faStarHalf as fasStarHalf } from '@fortawesome/pro-solid-svg-icons/faStarHalf'
import { faStarHalfAlt as fasStarHalfAlt } from '@fortawesome/pro-solid-svg-icons/faStarHalfAlt'
import { faTicketAlt as fasTicketAlt } from '@fortawesome/pro-solid-svg-icons/faTicketAlt'
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faUserCheck as fasUserCheck } from '@fortawesome/pro-solid-svg-icons/faUserCheck'
import { faUserPlus as fasUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faUsers as fasUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
library.add(
  fabFacebookF,
  fabLinkedinIn,
  fabTwitter,
  falAngleDown,
  falAngleRight,
  falArrowAltToBottom,
  falArrowToBottom,
  falBell,
  falCalendarAlt,
  falChevronDown,
  falEdit,
  falFileCertificate,
  falFileDownload,
  falFileImport,
  falGlobeEurope,
  falHeadSideBrain,
  falInfoCircle,
  falLanguage,
  falLightbulbDollar,
  falLock,
  falMedal,
  falMinusCircle,
  falMoneyBillWave,
  falPaperclip,
  falPhone,
  falQuestionCircle,
  falShare,
  falSquare,
  falTag,
  falTimes,
  falTrash,
  falUndo,
  falUserHeadset,
  falUsers,
  falWindowClose,
  farAngleDown,
  farAngleRight,
  farArrowLeft,
  farArrowRight,
  farBalanceScaleLeft,
  farBan,
  farBars,
  farBell,
  farBriefcaseMedical,
  farBuilding,
  farCheck,
  farCheckCircle,
  farCheckDouble,
  farCheckSquare,
  farChevronDoubleDown,
  farChevronDoubleUp,
  farChevronDown,
  farChevronRight,
  farChevronUp,
  farCirclePlus,
  farClipboard,
  farClock,
  farCocktail,
  farCog,
  farCogs,
  farCoins,
  farComment,
  farEdit,
  farEnvelope,
  farEnvelopeOpen,
  farEnvelopeOpenText,
  farEuroSign,
  farExclamationCircle,
  farExclamationTriangle,
  farExternalLink,
  farEye,
  farFile,
  farFileDownload,
  farFileInvoiceDollar,
  farFlag,
  farFootballBall,
  farHandHoldingUsd,
  farHeart,
  farHeartbeat,
  farHourglass,
  farInfoCircle,
  farLockAlt,
  farLongArrowRight,
  farPhone,
  farPhoneAlt,
  farPlus,
  farSearch,
  farShieldHalved,
  farStar,
  farTimes,
  farTimesCircle,
  farToggleOff,
  farToggleOn,
  fasBars,
  fasCheck,
  fasCheckCircle,
  fasCheckSquare,
  fasCircle,
  fasClipboard,
  fasComments,
  fasCommentsAlt,
  fasCopy,
  fasDownload,
  fasEngineWarning,
  fasEnvelope,
  fasEuroSign,
  fasExternalLink,
  fasExternalLinkSquare,
  fasFileCertificate,
  fasInfo,
  fasInfoCircle,
  fasLink,
  fasLock,
  fasLockAlt,
  fasLongArrowRight,
  fasMegaphone,
  fasMinusCircle,
  fasMobile,
  fasMoneyBillWave,
  fasPen,
  fasPhone,
  fasPhoneAlt,
  fasPlus,
  fasSearch,
  fasShieldHalved,
  fasShoppingBag,
  fasSimCard,
  fasSpinner,
  fasSquare,
  fasStar,
  fasStarHalf,
  fasStarHalfAlt,
  fasTicketAlt,
  fasTimes,
  fasTimesCircle,
  fasTrash,
  fasUser,
  fasUserCheck,
  fasUserPlus,
  fasUsers
)
const css = dom.css()
dom.watch()
dom.insertCss(css)
