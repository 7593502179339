import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

// * Fix from : https://github.com/jquery/jquery/issues/2871#issuecomment-497963776
jQuery.event.special.touchstart = {
  setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, { passive: true })
  }
}
